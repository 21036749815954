import { createSlice } from "@reduxjs/toolkit";

export const racewaySlice = createSlice({
    name: "raceway",
    initialState: {
        data: [],
        isLoading: false,
        isError: false,
        isComplete: false,
        msgError: ''
    },
    reducers: {
        loading: (state) => {
            state.isLoading = true
        },
        completed: (state, action) => {
            state.isLoading = false
            state.isComplete = true
            state.data = action.payload
        },
        error: (state, action) => {
            state.isError = true
            state.msgError = action.payload
        }
    }
})

export const { completed, loading, error } = racewaySlice.actions
export default racewaySlice.reducer