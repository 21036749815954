import './css/footer.css'
import logo32 from '../img/logo/ra32.png'
import { Link } from 'react-router-dom'

const Footer = (props) => {
    const {lb} = props
    
    return(
        <footer>
            <section className="bg-quartz container">
                <div className='flex flex-between'>
                    <img className='w32' src={logo32} />
                    <Link role="button" to="/credits">{lb}</Link>
                    <span>&copy; 2024</span>
                </div>
            </section>
        </footer>
	)
}

export default Footer

