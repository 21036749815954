const img_car = {
    "NSX": "https://www.torn.com/images/items/78/small.png",
    "Lexus": "https://www.torn.com/images/items/522/small.png",
    "R8": "https://www.torn.com/images/items/518/small.png",
    "Cosworth": "https://www.torn.com/images/items/511/small.png"
}

const ImgCar = (props) =>{
    const {carname, cartype} = props
    let name = carname.includes('NSX') ? 'NSX' : carname
    var lb = img_car[name]

    var w = 38
    var h = 19
    if(props.small){
        w = 28
        h = 14
    }

    return (
        <img src={lb} title={name +' '+ cartype} width={w} height={h} alt={name} />
    )
}

export default ImgCar;