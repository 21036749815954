
import { Link } from "react-router-dom"
import ImageBlur from "../components/imageBlur";
import DevTip from "../components/devtip";

import foto from '../img/foto500ogadiff.png'
import placeholderFoto from '../img/foto150ogadiff.png'

const HomePage = (props) => {
    const {data} = props
    const {home, nav} = data

    const {summary, my_work, skills, job_block, social_block} = home
    
    const cleanPar = (list) => {
        // une una lista en un string unico
        // luego separa donde haya \n para agregar salto <br>
        return (list.join(' ').split('\n').map(x => <p>{x}</p>))
    }

    return(
        <main className='main'>
            <section>
                {/* INTRO TEXT */}
                <div className="flex-md">
                    <ImageBlur className="avatar" src={foto} placeholderSrc={placeholderFoto} width="150" height="150"/>
                    <span className="summary">{cleanPar(summary)}</span>
                </div>
            </section>

            <section>
                {/* MY WORK */}
                <h2>{my_work.title}</h2>
                {cleanPar(my_work.descrip)}
            </section>

            {/* SKILLS BLOCK */}
            <section className="bg-quartz mt-md pb-0">
                <div className="row p-md">
                    {/* BLOCK RESUME LINK */}
                    <div className="col-6">
                        <div className="p-sm text-center">
                            <h4>{job_block.title}</h4>
                            <p>{job_block.subtitle}</p>
                            <div className="">
                                <Link className="btn btn-warning" role="button" to="/resume">{job_block.button}</Link>
                            </div>
                        </div>
                        <hr className="show-md"></hr>
                    </div>
                    {/* BLOCK SOCIAL LINKS */}
                    <div className="col-6">
                        <div className="p-sm text-center">
                            <h4>{social_block.title}</h4>
                            <p>{social_block.subtitle}</p>
                            <div className="flex flex-center">
                                <a className="btn-link" href="https://www.instagram.com/rob.aguero/" title="Instagram">
                                    <img src="../img/rrss/instagram_white.png" alt="Instagram" /></a>
                                <a className="btn-link" href="https://www.linkedin.com/in/roberto-ag%C3%BCero-6436b378/" title="LinkedIn">
                                    <img src="../img/rrss/linkedin_white.png" alt="LinkedIn" /></a>
                                <a className="btn-link" href="https://x.com/roberto_aguero" title="Twitter">
                                    <img src="../img/rrss/twitter_white.png" alt="Twitter" /></a>
                                <a className="btn-link" href="https://github.com/r-aguero" title="Github">
                                    <img src="../img/rrss/github_white.png" alt="Github" /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="m-sm"/>
            </section>
        </main>
	)
}

export default HomePage

