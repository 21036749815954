import Loading from "../components/loading"
import jsonProjects from '../texts/projects.json'
import { useState, useEffect } from "react"
import ProjectList from "../components/projectlist"

const ProjectsPage = (props) => {
    const [isLoading, setIsLoading] = useState(true)
    const [modalIsOpen, setIsOpen] = useState(false);

    const [tx, setTx] = useState({});
    useEffect(() => {
        setTx(jsonProjects);
        setIsLoading(false)
    }, []);

    const cleanPar = (list) => {
        // une una lista en un string unico
        // luego separa donde haya \n para agregar salto <br>
        let c = 1
        return (list.join(' ').split('\n').map(x => <p key={c++}>{x}</p>))
    }

    return(
        <main id="main-content" className="main">
            {isLoading ? <Loading /> :
            <section>
                <h1>{tx.title}</h1>
                <div className="text-secondary mb-md">{cleanPar(tx.summary)}</div>
                <ProjectList data={tx.list} />
            </section>}
        </main>
	)
}

export default ProjectsPage

