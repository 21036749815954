import './css/header.css'

import { NavLink } from 'react-router-dom'
import { Link } from 'react-router-dom'

const Header = (props) => {
const { data, nav } = props

    return(
        <header>
            <div className="container">
                <nav className='navbar'>
                    <Link className="logo" to="/">
                        <span>{data.name}</span>
                        <span className="me-blue">{data.lastname}</span>
                    </Link>

                    <ul>
                        <li><NavLink to="/home">{nav.home}</NavLink></li> 
                        <li><NavLink to="/resume">{nav.resume}</NavLink></li>
                        <li><NavLink to="/projects">{nav.projects}</NavLink></li>
                        <li>
                            <button title="Ver en español" onClick={() => props.selectLanguage('es')}>
                            <img src="./img/ico/espana.png" width={24} alt="Español" /></button>
                        </li>
                        <li>
                            <button title="View in english" onClick={() => props.selectLanguage('en')}>
                            <img src="./img/ico/usa.png" width={24} alt="English" /></button>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
            
	)
}

export default Header

