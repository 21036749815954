import './css/raceway-details.css';
import ImgCar from './imgcar';
import {formatTime} from '../../../components/utils'
import { useState } from 'react';

const Trophy = (props) => {
    var cl = ''
    switch(props.pos){
        case 1: cl = 'icon-trophy icon-first'; break;
        case 2: cl = 'icon-trophy icon-second'; break;
        case 3: cl = 'icon-trophy icon-third'; break;
        default: cl = ''; break;
    }

    return(<span className='flex flex-left'>
            <span>{props.pos}</span>
            <i className={cl} />
        </span>
    )
}

const InputSearch = (props) =>{
    return(
        <div className='input-group'>
            <label>Filter</label>
            <input type="text" placeholder="Search..." 
                onChange={e=>props.fn(e.target.value)} />
            <span className='bg-red'><i className='fas fa-search'/></span>
        </div>
    )
}


const RacewayDetails = (props) =>{
    const {data} = props
    const [searchValue, setSearchValue] = useState('')

    const filterData = () => {
        return(
        data.rows.filter((val) => {
            if(searchValue === ''){
                return val
            }else if(val.track.toLowerCase().includes(searchValue.toLowerCase())
                || val.car.toLowerCase().includes(searchValue.toLowerCase())
            ){
                return val
            }
        }).map(t => 
            <tr key={t.key}>
                <td>{t.track}</td>
                <td><ImgCar carname={t.car} cartype={t.type} small /> {t.car}</td>
                <td>{t.type}</td>
                <td><Trophy pos={t.pos} /></td>
                <td>{formatTime(t.time)}</td>
                <td>{formatTime(t.best)}</td>
            </tr>
        ))
    }
    
    return(<section>
            <div className='flex flex-between'>
                <InputSearch fn={setSearchValue} />
                <h5>Total races: {data.races}</h5>
            </div>
            <table className='striped'>
                <thead>
                <tr>
                    <th>Track</th>
                    <th>Car</th>
                    <th>Type</th>
                    <th>Pos</th>
                    <th>Time</th>
                    <th>Best Lap</th>
                </tr>
                </thead>
                <tbody>
                    {filterData()}
                </tbody>
            </table>
        </section>
    )

}

export default RacewayDetails;