import './css/torn-bazaar.css'
import Loading from "../../../components/loading"
import { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { Tab, Tabs, Box, Paper, Typography, Stack } from '@mui/material';


import Neon from '../../../components/animatext/neon'
import BazaarTable from './bazaar-table';
import jsonData from "./data/torn-bazaar.json";

/*
Para que funcione todo esto:
    INDEX.JS
        - import { Provider } from 'react-redux';
        - <Provider store={store}>
    SLICE.JS
        - export const counterSlice = createSlice({})
        - export const { increment, decrement, incrementValue} = counterSlice.actions
        - export default counterSlice.reducer
    STORE.JS
        - export default configureStore({})
    MODULO.JS
        - usar dispatch(accion()) definidas en el slice
*/

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div 
            role="tabpanel"
            hidden={value !== index}
            id={`bazaar-tabpanel-${index}`}
            aria-labelledby={`bazaar-tab-${index}`}
            {...other}
        >
            {value === index && ( <div>{children}</div> )}
        </div>
    );
}

const Categories = ({data}) =>{
    const [selectedTab, setSelectedTab] = useState(0);
    const handleChange = (event, selTab) => {
        setSelectedTab(selTab);
    };

    return(
    <div>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Tabs value={selectedTab} onChange={handleChange} centered>
                {data.map((categ, index) =>
                    <Tab key={categ.name} label={categ.name} id={`bazaar-tab-${index}`} />
                )}
            </Tabs>

        </Box>
        <Stack direction='row' sx={{p:2}} justifyContent="center">
            <Paper>
                {data.map((categ, index) =>
                    <TabPanel key={categ.name} value={selectedTab} index={index}>
                        <BazaarTable items={categ.items} />
                    </TabPanel>
                )}
            </Paper>
        </Stack>
    </div>
    )
}

const TornBazaar = (props) => {
    const [items, setItems] = useState({});
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        window.scrollTo(0, 0);
        setItems(jsonData)
        setIsLoading(false)
    }, []);

    return(
        <div id="torn-bazaar" className="bg-quartz">
            <header>
                <img src='/img/bg/unsplash-bazaar-01-1920x400.jpg' alt="Bazaar alley" />
                <span className='title'>
                    <h3>TORN CITY</h3>
                    <Neon tx="BAZAAR" glow="#ff65bd" on="#ffe6ff" off="#c1c1c1" />
                </span>
            </header>
            <main>
                {isLoading ? <CircularProgress /> : <Categories data={items} />}
            </main>
        </div>
	)
}


export default TornBazaar

