import './css/loading.css'

export const Loading = (props) =>{
    return(
        <div className="spinner-circle">
            <div className="spinner"></div>
        </div>
    )
}

export default Loading;