import './App.css'
import { useState, useEffect } from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'

import Header from './components/header'
import Footer from './components/footer'

import HomePage from './pages/homepage'
import ResumePage from './pages/resumepage'
import ProjectsPage from './pages/projectspage'
import CreditsPage from './pages/creditspage'

import { Loading } from './components/loading'

import TornRaceway from './pages/projects/torn-raceway/torn-raceway'
import TornBazaar from './pages/projects/torn-bazaar/torn-bazaar'

/* PARA USAR ROUTER 
 - agregar <BrowserRouter> EN index.js
*/

import jsonES from "./texts/tx_es.json";
import jsonEN from "./texts/tx_en.json";

function App() {
    const [tx, setTx] = useState({});
    const [idioma, setIdioma] = useState('es')
    const [isLoading, setIsLoading] = useState(true)
    const [bg, setBg] = useState('ignasi');
    const {pathname} = useLocation()

    useEffect(() => {
        setTx(idioma === 'es' ? jsonES : jsonEN);
        selectLanguage(idioma)
        setIsLoading(false)
    }, []);

    const selectLanguage = async (s) => {
        setIdioma(s)
        
        if(s === 'es'){
            document.documentElement.lang = 'es'
            setTx(jsonES);
        }else{
            document.documentElement.lang = 'en'
            setTx(jsonEN);
        }
    }

    //<BlockBgPicker setBg={setBg} tx={tx.nav.pick_bg} />
    const renderPage = () => {
        return(
            <div className='bg-container' >
                <Header nav={tx.nav} data={tx.basic} selectLanguage={selectLanguage} />
                <Routes>
                    <Route exact path="/" element={  
                        <Navigate to="/home" replace={true} />
                    } />
                    <Route path="/home" element={ <HomePage data={tx} /> } />
                    <Route path="/resume" element={ <ResumePage data={tx} /> } />
                    <Route path="/projects" element={<ProjectsPage data={tx.projects} /> } />
                        
                    <Route path="/credits" element={<CreditsPage data={tx.credits} /> } />
                    <Route path="*" element={ <h1>404: no hay</h1> } />
                </Routes>
                <Footer lb={tx.credits.title} />
            </div>
        )
    }

    const renderProject = () =>{
        return(
            <Routes>
                <Route path="/projects/raceway/*" element={<TornRaceway /> } />
                <Route path="/projects/market/*" element={<TornBazaar /> } />
            </Routes>
            )
    }

    const isProject = () =>{
        if(pathname.includes('/projects/')){
            return true
        }
        return false
    }

    

    return ( isLoading ? <Loading fullscreen /> : 
        isProject() ? renderProject() : renderPage() )
}

export default App;
