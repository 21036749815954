import './css/projectlist.css'
import { Link } from 'react-router-dom';
import TitledImage from './titledimage';

const ProjectList = (props) =>{
    
    return(
        <div className="deck">
            {props.data.map(p => 
                <div key={p.id} className="card-wrap">
                    <div className="card">
                        <h2 className=''>{p.name}</h2>
                        <div className='card-body'>
                            <p>{p.summary}</p>
                            <div className='bg-quartz p-sm rounded'>
                                <TitledImage src={p.img} imgClass="" />
                            </div>
                            <p className='flex my-md'>
                                {p.tags.map(tag =><span key={tag} className='pill bg-info'>{tag}</span>)}
                            </p>
                        </div>
                        <div className='card-footer'>
                            <small>Last update: {p.last_update}</small>
                            <Link className="btn btn-primary" role="button" to={p.link}>Open</Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ProjectList;