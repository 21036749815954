import './css/titledimage.css'
import { useState, useEffect } from 'react';
import Loading from './loading'

const TitledImage = (props) => {
    const [imgSrc, setImgSrc] = useState(null);
  
    useEffect(() => {
        const img = new Image();
        img.src = props.src;
        img.onload = () => {
          setImgSrc(img.src);
        };
    }, []);
    
    return (imgSrc ? 
          <div className='titled-img'>
              {props.title ? <h4>{props.title}</h4> : null}
              <img className={props.imgClass} src={imgSrc} alt={props.alt} />
          </div>
          : 
          <Loading />
    )
};
export default TitledImage;