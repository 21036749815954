import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Typography, Grid, Chip } from '@mui/material';
import Stack from '@mui/material/Stack';
import ItemImg from './ItemImg';
import { IconButton } from '@mui/material';

import { Outbound, ArrowDropUp, ArrowDropDown, SwapHoriz, SyncAlt } from '@mui/icons-material';

import { pending, loading, completed, error } from "./features/bazaarSlice"
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react';

import NumberFormat from 'react-number-format';

const getTornData = (items) => async dispatch => {
  const APIKEY = 'pgGdbLzBTr6BAsa1';
  const DEFAULT_RATE = 3; // 3% profit
  dispatch(loading())

  try{
    let lista = []
    const n = items.length;
    for(var i = 0; i < n; i++){
      let itemid = items[i].id
      const json = `https://api.torn.com/market/${itemid}?selections=bazaar&key=${APIKEY}`;
      const response = await fetch(json);
      let data = await response.json();

      /*
      let amount = data.bazaar[0].cost
      let co = Math.round(amount * 0.9)
      let profit = amount - co
      let sl = co + profit
      */

      let LIMITE = 4
      let q = 0
      let price = 0
      let min = data.bazaar[0].cost
      let max = data.bazaar[0].cost

      for(let i = 0; i < LIMITE; i++){
          if(data.bazaar[i]){
            if(data.bazaar[i].cost > max){ max = data.bazaar[i].cost; }
            if(data.bazaar[i].cost < min){ min = data.bazaar[i].cost; }
            
            q += data.bazaar[i].quantity;
            price += (data.bazaar[i].cost * data.bazaar[i].quantity)
          }
      }

      let average = Math.round(price / q)
      let rate = items[i].rate ? items[i].rate : DEFAULT_RATE
      let psell = custom_round( average )
      let pbuy = custom_round( psell - psell * (rate/100) )
      let quantity = pretty_quantity(q)

      lista.push({
        ...items[i],
        average: average,
        quantity: quantity,
        min: min,
        max: max,
        rate: rate,
        buy_price: pbuy,
        sell_price: psell,
        profit: psell - pbuy
      })
    }

    dispatch(completed(lista)) // envia json(data) como payload a state.bazaar
  }catch(e){
    dispatch(error(e.message)) // envia msg como payload a state.msgError
  }
}

function pretty_quantity(q){
  if(q > 1000000){ return Math.round( (q/1000000) * 10) / 10 + 'm' }
  if(q > 1000){ return Math.round( (q/1000) * 10) / 10 + 'k' }
  return q
}

function custom_round(amount){
  let res = 0;
  let price = 0;
  let fix = 0;
  
  if(amount >= 1000000){
      // nro mayor a 1M redondeo con 5000
      fix = amount % 10000;
      price = amount - fix;
      if(fix > 6500){
          res = price + 10000;
      }else{
          res = price;
      }
  }else if(amount >= 10000){ 
      // mayor a 10k redondeo a 100
      fix = amount % 100;
      price = amount - fix;
      if(fix > 65){
          res = price + 100;
      }else{
          res = price;
      }
  }else if(amount >= 500){ 
    // mayor a 1k redondeo de a 100 o 50
    fix = amount % 100;
    price = amount - fix;
    if(fix > 75){
        res = price + 100;
    }else if(fix > 25){
      res = price + 50;
    }else{
      res = price;
    }
  }else{ 
      // menor a 1k redondeo a 10
      fix = amount % 10;
      price = amount - fix;
      if(fix > 5){ 
          res = price + 10;
      }else{
          res = price;
      }
  }
  return res;
}

const initializeList = (items) => async dispatch => {
  dispatch(loading())
  try{
    let lista = []
    const n = items.length;
    for(var i = 0; i < n; i++){
      lista.push({
        ...items[i],
        prom: '',
        cant: '',
        min: '',
        max: '',
        profit: ''
      })
    }
    dispatch(completed(lista)) // envia json(data) como payload a state.bazaar
  }catch(e){
    dispatch(error(e.message)) // envia msg como payload a state.msgError
  }
}

const BazaarTable = ({ items }) =>{
  const dispatch = useDispatch()
  const bazaar = useSelector((state) => state.bazaar)

  useEffect(() => {
    dispatch(initializeList(items));
    dispatch( getTornData(items) );
  }, [])

  return(
    <TableContainer>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Typography variant="subtitle2" color='primary'>Item</Typography>
            </TableCell>
            <TableCell align="right">High/Low</TableCell>
            <TableCell align="right">Avg.</TableCell>
            <TableCell align="right">Buy</TableCell>
            <TableCell align="right">Sell</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bazaar.items.map(m => (
            <TableRow key={m.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
              <TableCell>
              <Stack direction={'row'} spacing={0}>
                <ItemImg src={`https://www.torn.com/images/items/${m.id}/medium.png`} w={60} h={30} />
                <span className='p-sm'>{m.name}</span>
              </Stack>
              </TableCell>
              <TableCell align="right">
                  {bazaar.status === 2 ? 
                  <div>
                    <div className='flex flex-center items-center'>
                      <ArrowDropUp color='success' />
                      <Numero value={m.max} />
                    </div>
                    <div className='flex flex-center items-center'>
                      <ArrowDropDown color='error' />
                      <Numero value={m.min} />
                    </div>
                  </div>
                  : bazaar.status === 1 ? <div><Skeleton /><Skeleton /></div>
                    : <Chip size="small" color="error" label='error' variant='outlined' />}
              </TableCell>
              <TableCell align="right">
                  {bazaar.status === 2 ? 
                  <div className='pl-md'>
                    <div className='flex flex-right items-center'>
                      <span className='text-secondary mb-xs'><Numero value={m.average} /></span>
                    </div>
                    <Chip size="small" label={m.quantity} />
                  </div>
                  : bazaar.status === 1 ? <div><Skeleton /><Skeleton /></div>
                    : <Chip size="small" color="error" label='error' variant='outlined' />}
              </TableCell>
              <TableCell align="right">
                  {bazaar.status === 2 ? 
                  <div className='pl-md'>
                    <div className='flex flex-right items-center'>
                      <span className='mb-xs'><Numero value={m.buy_price} /></span>
                    </div>
                    <Chip size="small" label={`${m.rate}%`} />
                  </div>
                  : bazaar.status === 1 ? <div><Skeleton /><Skeleton /></div>
                    : <Chip size="small" color="error" label='error' variant='outlined' />}
              </TableCell>
              <TableCell align="right">
                  {bazaar.status === 2 ? 
                  <div className='pl-md'>
                    <div className='flex flex-right items-center'>
                      <span className='mb-xs'><Numero value={m.sell_price} /></span>
                    </div>
                    <Typography variant="body2" color="success.main">+<Numero value={m.profit} /></Typography>
                  </div>
                  : bazaar.status === 1 ? <div><Skeleton /><Skeleton /></div>
                    : <Chip size="small" color="error" label='error' variant='outlined' />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const Numero = ({value}) =>{
  return(
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator={true}
      prefix={'$'}
    />
  )
}


export default BazaarTable;