import './css/raceway-summary.css';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, 
    Tooltip, Legend, ResponsiveContainer, ReferenceLine 
} from 'recharts';


const data = [
    {name: 'Page A', uv: 4000, pv: 2400, amt: 2400, },
    {name: 'Page B', uv: 3000, pv: 1398, amt: 2210, },
    { name: 'Page C', uv: 2000, pv: 9800, amt: 2290, },
    { name: 'Page D', uv: 2780, pv: 3908, amt: 2000, },
    { name: 'Page E', uv: 1890, pv: 4800, amt: 2181, }
  ];

const RacewaySummary = (props) =>{
    
    const getData = () =>{
        var res = []
        props.data.forEach(x => {
            res.push({
                track: x.track,
                first: x.first,
                second: x.second,
                third: x.third,
                lose: (x.lose * -1)
            })
        });
        return res
    }

    return(<section>
        <div className='row'>
            <div className='col-6 px-sm'>
                <div className='intro p-md'>
                    <p>
                    Torn City is a text-based browser game. It's mainly about doing crimes 
                        and getting rich, but it also has several mini-games. 
                        One of them is the Raceway, where players can buy cars 
                        and upgrade their parts to compete on 16 tracks.
                    </p>
                    <p>
                        I made this site because I needed an overview of my car's 
                        performance and to see which car/upgrades work best on which tracks.
                    </p>
                </div>
                <div className='chart-container'>
                <ResponsiveContainer width="100%" height="100%">
                <BarChart
                        width={500}
                        height={300}
                        data={getData()}
                        stackOffset="sign"
                        barCategoryGap={"20%"}
                        margin={{
                            top: 32,
                            right: 32,
                            left: 0,
                            bottom: 5,
                        }}
                    >
                    <CartesianGrid strokeDasharray="1 2" />
                    <XAxis dataKey="track" />
                    <YAxis />
                    <Tooltip />
                    <Legend  />
                    <Bar dataKey="lose" stackId="a" fill="#371E30" />
                    <Bar dataKey="third" stackId="a" fill="#BB4430" />
                    <Bar dataKey="second" stackId="a" fill="#8A8D91" />
                    <Bar dataKey="first" stackId="a" fill="#FCBA04" />
                    </BarChart>
                </ResponsiveContainer>
                </div>
            </div>
            <div className='col-6 px-sm'>
                <table className='striped'>
                <thead>
                <tr>
                    <th>Track</th>
                    <th>Races</th>
                    <th>1st</th>
                    <th>2nd</th>
                    <th>3rd</th>
                    <th>Lose</th>
                </tr>
                </thead>
                <tbody>
                {props.data.map(t => 
                    <tr key={t.track}>
                        <td>{t.track}</td>
                        <td>{t.total}</td>
                        <td>{t.first}</td>
                        <td>{t.second}</td>
                        <td>{t.third}</td>
                        <td>{t.lose}</td>
                    </tr>
                )}
                </tbody>
                </table>
            </div>
        </div>
    </section>)

}

export default RacewaySummary;