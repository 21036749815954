import './css/raceway-track.css';
import TitledImage from '../../../components/titledimage';
import styled from 'styled-components';
import ImgCar from './imgcar';
import {formatTime} from '../../../components/utils'

const Badge = styled.span`
	padding: 0 4px;
    font-size: 1.25em;
    ${props => `
        ${props.winrate >= 0 && `
            background-color: var(--danger);
            color: white;
        `}
        ${props.winrate >= 10 && `
            background-color: var(--warning); 
            color: black;
        `}
        ${props.winrate >= 19 && `
            background-color: var(--success); 
            color: white;
        `}
        
    `}
`
const Overall = (props) =>{
    const {data} = props
    return(
        <div className='flex flex-right overall border-b'>
            <span className='mr-sm'>winrate</span>
            <Badge winrate={data.p_first}>{data.p_first}%</Badge>
        </div>
    )
}

const BestTimes = (props) =>{
    return(
        <div className='py-sm border-b'>
            <h6>Best Time (Lap)</h6>
            {props.data.map(p =>
                <div key={p.key} className='flex flex-center track-car'>
                    <ImgCar carname={p.car} cartype={p.type} />
                    <span className="best">{formatTime(p.time)}</span>
                    <span>({formatTime(p.best_lap)})</span>
                </div>
            )}
        </div>
    )
}

const BestLaps = (props) =>{
    return(
        <div className='m-sm'>
            <h6>Fastest Laps</h6>
            {props.data.map(b =>
                <div key={b.key} className='flex flex-center track-car'>
                    <ImgCar carname={b.car} cartype={b.type} />
                    <span className="best">{formatTime(b.best_lap)}</span>
                </div>
            )}
        </div>
    )
}

const BestWinrate = (props) =>{
    return(
        <div className='py-sm border-b'>
            <h6>Best Winrate</h6>
            {props.data.map(p =>
                <div key={p.car} className='flex flex-center track-car'>
                    <ImgCar carname={p.car} cartype={p.type} />
                    <span className='total' title="total races">{p.races}</span>
                    <i className='icon-trophy icon-first'/><span>{p.first}%</span>
                    <i className='icon-trophy icon-second'/><span>{p.second}%</span>
                    <i className='icon-trophy icon-third'/><span>{p.third}%</span>
                </div>
            )}
        </div>
    )
}


const RacewayTrack = (props) =>{
    return(
        <section>
        <div className='flex flex-items-stretch flex-center'>
            {props.data.map(p =>
                <div key={p.track} className="track">
                    <TitledImage title={p.track} src={`/img/torn/racetrack/${p.track}.jpg`} imgClass="mw-300" />
                    <Overall data={p.overall} />
                    <BestTimes data={p.best_times} />
                    <BestWinrate data={p.winrate} />
                    <BestLaps data={p.best_laps} />
                </div>
            )}
        </div>
        </section>
    )

}

export default RacewayTrack;