import './css/torn-raceway.css'
import { loading, completed, error } from "./features/racewaySlice"
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loading from "../../../components/loading"

import RacewayTrack from "./raceway-track"
import RacewaySummary from './raceway-summary'
import RacewayDetails from './raceway-details'

import jsonRaceway from './data/raceway_top3.json' // data
import racewaySummary from './data/raceway_summary.json'
import racewayDetails from './data/raceway_details.json'

import Neon from '../../../components/animatext/neon'
import Flicker from '../../../components/animatext/flicker'

/*
Para que funcione todo esto:
    INDEX.JS
        - import { Provider } from 'react-redux';
        - <Provider store={store}>
    SLICE.JS
        - export const counterSlice = createSlice({})
        - export const { increment, decrement, incrementValue} = counterSlice.actions
        - export default counterSlice.reducer
    STORE.JS
        - export default configureStore({})
    MODULO.JS
        - usar dispatch(accion()) definidas en el slice
*/

const getRacewayJson = () => async dispatch => {
    dispatch(loading())
	try{
		//const response = await fetch(json)
        //console.log(response)
		//const data = await response.json()
		dispatch(completed(jsonRaceway)) // envia jsonTest como payload a state.raceway
	}catch(e){
		dispatch(error(e.message)) // envia msg como payload a state.msgError
	}
}

const getRacewaySummary = () => async dispatch => {
    dispatch(loading())
	try{
		//const response = await fetch(json)
        //console.log(response)
		//const data = await response.json()
		dispatch(completed(racewaySummary)) // envia jsonTest como payload a state.raceway
	}catch(e){
		dispatch(error(e.message)) // envia msg como payload a state.msgError
	}
}

const getRacewayDetails = () => async dispatch => {
    dispatch(loading())
	try{
		//const response = await fetch(json)
        //console.log(response)
		//const data = await response.json()
		dispatch(completed(racewayDetails)) // envia jsonTest como payload a state.raceway
	}catch(e){
		dispatch(error(e.message)) // envia msg como payload a state.msgError
	}
}

const TornRaceway = (props) => {
    const dispatch = useDispatch()
    const top3 = useSelector((state) => state.raceway)
    const [tab, setTab] = useState('summary')
    
    const renderContent = () => {
        return(<div id="content">
            {top3.isLoading ? <Loading /> : null }
            {top3.isComplete ? 
                tab === 'track' ? <RacewayTrack data={top3.data} />
                : tab === 'summary' ? <RacewaySummary data={top3.data} />
                : tab === 'details' ? <RacewayDetails data={top3.data} />
                : null
            : null}
            {top3.isError ? <p className="alert-danger">Error: {top3.msgError}</p> : null }
        </div>)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        handleTabs(tab);
    }, []);

    const handleTabs = async (s) => {
        setTab(s)
        
        if(s === 'summary'){
            dispatch(getRacewaySummary())
        }else if(s === 'track'){
            dispatch(getRacewayJson())
        }else if(s === 'details'){
            dispatch(getRacewayDetails())
        }
    }

    //<Neon on='#FFFFFF' glow='#01b4b8' >Torn City Raceway</Neon>

    return(<div id="torn-raceway">
            <header>
                <img src='/img/bg/unsplash04_1920x600.jpg' alt="Car speeding" />
                <Flicker>TO#RN CITY RA@CEWA&Y</Flicker>
                <nav>
                    <li className={tab === 'summary' ? 'active':  ''}>
                        <a onClick={() => handleTabs('summary')}>Summary</a></li>
                    <li className={tab === 'track' ? 'active':  ''}>
                        <a onClick={() => handleTabs('track')}>Tracks</a></li>
                    <li className={tab === 'details' ? 'active':  ''}>
                        <a onClick={() => handleTabs('details')}>Details</a></li>
                    <li><Link to="/projects">Back<i className="ml-sm fas fa-arrow-alt-circle-right" /></Link></li>
                </nav>
            </header>
            <main>
                {renderContent()}
            </main>
        </div>
	)
}


export default TornRaceway

