import './css/image.css'
import { useState, useEffect } from 'react';


const ImageBlur = ({ placeholderSrc, src, className, ...props }) => {
    const [imgSrc, setImgSrc] = useState(placeholderSrc || src);
  
    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          setImgSrc(src);
        };
    }, [src]);
  
    const blurimg = placeholderSrc && imgSrc === placeholderSrc ? "image-loading" : "image-loaded";

    return (
      <img
        {...{ src: imgSrc, ...props }}
        alt={props.alt || ""}
        className={`${className} ${blurimg}`}
      />
    )
};
export default ImageBlur;