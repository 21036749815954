import { useState, useEffect } from 'react';
import { Skeleton } from '@mui/material';

const ItemImg = ({ src, className, w, h, ...props }) => {
    const [imgSrc, setImgSrc] = useState(src);
    const [loaded, setLoaded] = useState(false);
  
    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          setImgSrc(src);
          setLoaded(true);
        };
        
    }, [src]);
  
    return ( loaded ? <img src={imgSrc} width={w} height={h} {...props} /> : <Skeleton width={w} height={h} /> )
};
export default ItemImg;