import { createSlice } from "@reduxjs/toolkit";

/*
STATUS:
    0- loading
    1- completed
    3- error
*/

export const bazaarSlice = createSlice({
    name: "bazaar",
    initialState: {
        items: [],
        status: 0,
        msgError: ''
    },
    reducers: {
        pending: (state, action) => {
            state.status = 0
            state.items = action.payload
        },
        loading: (state) => {
            state.status = 1
        },
        completed: (state, action) => {
            state.status = 2
            state.items = action.payload
        },
        error: (state, action) => {
            state.status = 3
            state.msgError = action.payload
        }
    }
})

export const { pending, completed, loading, error } = bazaarSlice.actions
export default bazaarSlice.reducer