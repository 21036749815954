import { useState, useEffect } from "react"
import { Loading } from "../components/loading"
import jsonCredits from '../texts/credits.json'

const CC3 = (props) => {
    const {data} = props
    var s = data.split('#')

    return(
        <p className="text-secondary">
            {s[0]}
            <a className="me-link" href="https://www.flaticon.com/">www.flaticon.com</a>
            {s[1]}
            <a className="me-link" href="http://creativecommons.org/licenses/by/3.0/">CC 3.0</a>
            {s[2]}
        </p>
    )
}

const Flaticons = (props) => {
    const {data, dir} = props
    return(
        <div className="flex">
            {data.map(ico =>
                <img className="thumbnail" width={48} src={`./${dir}/${ico}.png`} 
                key={ico} title={ico} alt={ico} data-bs-toggle="tooltip" />
            )}
        </div>
    )
    
}

const Authors = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [json, setJson] = useState([]);
    useEffect(() => {
        setJson(jsonCredits);
        setIsLoading(false)
        
    }, []);
    
    return(
        <ul className="card-list">
            {isLoading ? <Loading w={48} /> : 
                json.map(x => <li key={x.author}>
                        <a href={x.link} target="_blank"><b>{x.author}</b></a> 
                        <div>Pack: <a href={x.pack.link} target="_blank">{x.pack.name}</a></div>
                        <Flaticons data={x.icons} dir={x.pack.dir} />
                    </li>
                )
            }
        </ul>
    )
}

const CreditsPage = (props) => {
    const {data} = props

    return(
        <main className='main'>
            <section>
                <img className="float-right ml-md" width={64} src='./img/gradient/color-palette.png' />
                <h1 className="text-sketch">{data.title}</h1>
                <CC3 data={data.credits} />
                <h2 className="text-valera">{data.authors}</h2>
                <Authors />
            </section>
        </main>
	)
}

export default CreditsPage

