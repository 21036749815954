
const Ico = (props) =>{
    return ( <i className={`${props.icon} float-md-right text-secondary m-sm`} /> )
}

const Basic = (props) => {
    const {data} = props
    return(
        <div className="row">
            <div className="col-6">
                <h4><b>{data.name} {data.lastname}</b></h4>
                <h5>{data.label}</h5>
                <h5 className="mb-md">{data.location}</h5>
            </div>
            <div className="col-6">
                <h5 className="text-md-right"><Ico icon="fas fa-envelope" />{data.email}</h5>
                <h5 className="text-md-right"><Ico icon="fas fa-phone" />{data.phone}</h5>
                <h5 className="text-md-right"><Ico icon="fas fa-globe-americas" />{data.website}</h5>
            </div>
        </div>
    )
}

const Work = (props) => {
    const {data} = props
    return(
        <div className="">
            {data.map(w => <div className="mb-md">
                <h4>{w.position}, {w.company}</h4>
                <p className="text-secondary"><b>{w.start} - {w.end}</b></p>
                <p>{w.summary}</p>
                <ul className="text-secondary">
                    {w.highlights.map(h =>
                        <li>{h}</li>
                    )}
                </ul>
            </div>
            )}
        </div>
    )
}

const Education = (props) => {
    const {data} = props
    return(
        <div className="">
            {data.map(ed => <div className="mb-md">
                <h4 className="mb-0">{ed.type}, {ed.area}</h4>
                <p className="mb-0">{ed.institution}</p>
                <p className="text-secondary"><b>{ed.start} - {ed.end}</b></p>
            </div>
            )}
        </div>
    )
}

const Languages = (props) => {
    const {data, lb} = props
    return(
        <div className="">
            {data.map(lng => <div className="mb-md">
                <h4>{lng.language}</h4>
                <p className="mb-0"><b>{lb}</b> {lng.fluency}</p>
            </div>
            )}
        </div>
    )
}

const Skills = (props) => {
    //const x = Math.ceil(n / 2)
    return (
        <div className="row">{props.data.map(skill => 
            <div key={skill.name} className="col-6">
                <div className="skill-card">
                    <img src={`./img/gradient/${skill.icon}.png`} alt={skill.name} />
                    <h5 className="">{skill.name}</h5>
                    {skill.tags.map(tag =>
                        <span className="badge">{tag}</span>
                    )}
                </div>
            </div>
        )}</div>
    );
};

const cleanPar = (list) => {
    // une una lista en un string unico
    // luego separa donde haya \n para agregar salto <br>
    return (list.join(' ').split('\n').map(x => <p>{x}</p>))
}

const ResumePage = (props) => {
    const {data} = props
    const { basic, resume, home} = data
    const {skills, work_experience, education, languages} = resume

    return(
        <main className='main'>
            <section>
                <h1>{resume.title}</h1>
                <Basic data={basic} />
                <hr/>
            </section>

            <section>
                <img className="float-right" src="./img/gradient/info.png" alt="Information" />
                <h2>{home.title}</h2>
                <span className="text-secondary">{cleanPar(home.summary)}</span>
            </section>
            
            <section>
                <h2>{skills.title}</h2>
                <Skills data={skills.list} />
            </section>

            <section>
                <img className="float-right" src="./img/gradient/briefcase.png" alt="Briefcase" />
                <h2>{work_experience.title}</h2>
                <Work data={work_experience.list} />
            </section>
            
            <section>
                <img className="float-right" src="./img/gradient/graduation-cap.png" alt="Graduation cap" />
                <h2>{education.title}</h2>
                <Education data={education.list} />
            </section>
            
            <section>
                <img className="float-right" src="./img/gradient/chat.png" alt="Chat bubble" />
                <h2>{languages.title}</h2>
                <Languages data={languages.list} lb={languages.label} />
            </section>
        </main>
	)
}

export default ResumePage

