import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../pages/projects/torn-raceway/features/counterSlice'
import racewayReducer from '../pages/projects/torn-raceway/features/racewaySlice'
import bazaarReducer from '../pages/projects/torn-bazaar/features/bazaarSlice'

export default configureStore({
    reducer: {
        counter: counterReducer,
        raceway: racewayReducer,
        bazaar: bazaarReducer
    }
})