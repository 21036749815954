import './flicker.css'

const Flicker = (props) =>{
  const {children} = props
  
  const getText = () =>{
    let res = []
    let v = children.split("")
    for(var i = 0; i < v.length; i++){
      if(v[i] === '#'){
        res.push(<span key={i} className='blink-1'>{v[i+1]}</span>)
        i++
      }else if(v[i] === '@'){
        res.push(<span key={i} className='blink-2'>{v[i+1]}</span>)
        i++
      }else if(v[i] === '&'){
        res.push(<span key={i} className='blink-3'>{v[i+1]}</span>)
        i++
      }else{
        res.push(v[i]) 
      }
    }

    return res
  }

  return (
    <h1 className='text-bebas flicker'>{
      getText()
    }</h1>
  )
}

export default Flicker;