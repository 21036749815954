import styled from 'styled-components';

const H1 = styled.h1`
  animation: neon-text-glow 1s ease-in-out infinite alternate;
  -webkit-animation: neon-text-glow 1s ease-in-out infinite alternate;
  -moz-animation: neon-text-glow 1s ease-in-out infinite alternate;
  
  @keyframes neon-text-glow {
    from {
      color: ${props => props.colorOn};
      text-shadow: 0 0 8px ${props => props.colorGlow}, 
                  0 0 16px ${props => props.colorGlow}, 
                  0 0 32px ${props => props.colorGlow};
    }
    to {
      color: ${props => props.colorOff};
      text-shadow: 0 0 8px ${props => props.colorGlow},
                  0 0 16px ${props => props.colorGlow},
                  0 1 32px ${props => props.colorGlow};
    }
  }
  @-webkit-keyframes neon-text-glow {
      from {
        color: #FFFFFF;
        text-shadow: 0 0 8px ${props => props.colorGlow}, 
                    0 0 16px ${props => props.colorGlow}, 
                    0 0 32px ${props => props.colorGlow};
    }
    
    to {
      color: ${props => props.colorOff};
      text-shadow: 0 0 8px ${props => props.colorGlow},
                    0 0 16px ${props => props.colorGlow},
                    0 1 32px ${props => props.colorGlow};
    }
  }
`

const Neon = (props) =>{
  const c1 = props.on ? props.on : '#FFFFFF'
  const c2 = props.off ? props.off : '#B8B08D'
  const c3 = props.glow ? props.glow : '#01b4b8'
  
  return (
    <H1 colorOn={c1} colorOff={c2} colorGlow={c3}>{props.tx}</H1>
  )
}

export default Neon;